export enum WeekDay {
	monday = 'Monday',
	tuesday = 'Tuesday',
	wednesday = 'Wednesday',
	thursday = 'Thursday',
	friday = 'Friday',
	saturday = 'Saturday',
	sunday = 'Sunday',
	specific = 'Specific',
};

export const weekNumberDay = {
	[WeekDay.monday]: 0,
	[WeekDay.tuesday]: 1,
	[WeekDay.wednesday]: 2,
	[WeekDay.thursday]: 3,
	[WeekDay.friday]: 4,
	[WeekDay.saturday]: 5,
	[WeekDay.sunday]: 6,

};
export type Session = {
	times: string[];
	periodic: 'once' | 'weekly';
	experienceUid: string;
	weekDay: WeekDay;
	uid: string;
};
