export const CATEGORIES = [
	{
		key: 'connection',
		title: 'Connection',
		img: '/assets/icon/connection.svg',
		imgActive: '/assets/icon/connection_contrast.svg',
		description: 'is the feeling of being seen, acknowledged, and understood by others, particularly groups of people.',
		scoreSummary: {
			languishing: `You feel disconnected from the important people in your life, do not often feel
			like you belong to a stable and supportive community. You rarely feel like you help
			make your community a better place for people.`,
			functioning: `You sometimes feel connected to people in your life, but not always.
			You struggle sometimes to feel like your work or activities improve the lives of the
			people around you, and sometimes it’s difficult to feel a trusting relationship with
			other people.`,
			flourishing: `You feel deeply connected to people in your life, like your community benefits
			from your presence, and like you can trust the important people in your life.`
		},
		reflection: `
			When we feel connected with people, everything else in life feels more aligned: Work, relationships,
			our health–it all just flows better when we know we’re acknowledged and understood. <br>The best part?
			Connection fosters more trust and cooperation with others.<br>
			It’s a ripple effect: The more connection you feel to others, the more powerful connections
			you’ll bring into your life. <br>When it comes to connection, small moves make a big difference: Hug someone.<br>
			Have a face-to-face conversation with a friend (without checking your phone).<br>
			Cry in front of your partner. <br>These types of interactions may not seem like much,
			but they’ve been shown to spark real connection with other humans.
		`,
		chartOrder: 1,
		titleDescription: 'Better Together'
	},
	{
		key: 'adaptability',
		title: 'Adaptability',
		img: '/assets/icon/adaptability.svg',
		imgActive: '/assets/icon/adaptability_contrast.svg',
		description: `is the ability to effectively cope with changes in circumstance, particularly stressful or unexpected events.`,
		scoreSummary: {
			languishing: `You’re feeling frustrated when things don’t go as they should, being drained
			by changes to routine or schedule, and having a hard time managing day-to-day
			responsibilities when stressed out. You rarely experience things that help you grow
			and challenge how you think or act.`,
			functioning: `Changes to routine are consistently stressful, you feel like your head
			is “just above water” when it comes to managing your day-to-day responsibilities.
			Sometimes you experience things that help you grow and challenge how you think or
			act, but don’t regularly seek them out.`,
			flourishing: `You regularly feel comfortable with whatever your day or week throws at you,
			good or bad. You seek out and experience things that help you grow and challenge
			how you think or act.`
		},
		reflection: `
			Want to know a secret? Every single one of us has a superpower, and that superpower is mental flexibility.<br>
			The reason it’s so powerful is that it helps us navigate anything life throws at us in a healthy and consistent way.<br>
			There are so many benefits to productive pivoting: Mental flexibility helps us cope and regulate our emotions,
			overcome all types of obstacles and live a happier life. <br>To build this muscle,
			think about how you've coped with hardships in the past, and consider the skills and strategies
			that helped you through it. <br>You might even write about past experiences in a journal to help you
			identify positive and negative behavior patterns — and guide your future behavior.
		`,
		chartOrder: 2,
		titleDescription: 'Go with the flow'
	},
	{
		key: 'joy',
		title: 'Joy',
		img: '/assets/icon/happiness.svg',
		imgActive: '/assets/icon/happiness_contrast.svg',
		description: 'is the frequent feelings of positive emotion.',
		scoreSummary: {
			languishing: `You’re feeling lethargic, finding it very difficult enjoying activities that
			usually make you happy, and having difficulty motivating yourself to participate
			in joyful experiences.`,
			functioning: `Some activities are less joyful than they used to be,
			noticing less “happy” or “good” days than usual.`,
			flourishing: `You’re regularly feeling excited about your day, frequently being able
			to find moments of calm and peacefulness, and confident in handling
			the demands of your daily life.`
		},
		reflection: `
			Have you told yourself that once you accomplish a specific goal or dream, you’ll be happy? The problem with that rationale—and why our problems don’t magically disappear after we 'achieve' something—is that joy doesn’t come from success, mastery or any other external forces. Joy is a state of being, one that we can cultivate through experiences that center meaning, purpose and depth.

		`,
		chartOrder: 3,
		titleDescription: 'Good Vibes'
	},
	{
		key: 'confidence',
		title: 'Confidence',
		img: '/assets/icon/confidence.svg',
		imgActive: '/assets/icon/confidence_contrast.svg',
		description: 'is the feeling confident and competent in who you are, what you do, and why you exist.',
		scoreSummary: {
			languishing: `You have a difficult time feeling good about aspects of yourself,
			be it your personality, appearance, or how your life is turning out.
			Expressing your opinions can be difficult, especially when they differ from the majority.`,
			functioning: `You regularly experience self-consciousness about aspects of who you are or
			the beliefs you have. There are days where you feel confident, but it’s not consistent.`,
			flourishing: `You feel confident in who you are, how you behave, and the beliefs you have.
			When something shakes your confidence, you’re able to recover quickly.`
		},
		reflection: `
			There’s nothing more powerful than believing in yourself. When we possess a conviction
			that we can handle the emotional outcome of any situation, life opens up a world of possibility.<br>
			Confidence does not mean feeling invincible; rather, it demands a realistic sense of our
			capabilities—and being secure in that knowledge.<br>
			Here’s a good tip to build your confidence: Remember, everyone has strengths and talents.<br>
			What are yours? <br>Recognizing what you’re good at, and trying to build on those things,
			will help you to build confidence in your own abilities.
		`,
		chartOrder: 4,
		titleDescription: 'Confident Booster'
	},
	{
		key: 'presence',
		title: 'Presence',
		img: '/assets/icon/presence.svg',
		imgActive: '/assets/icon/presence_contrast.svg',
		description: `is the ability to live in the moment, savoring and enjoying the activities of life;
		 being absorbed in and excited by life activities.`,
		scoreSummary: {
			languishing: `It’s difficult to feel interested or present in the daily activities of your life.
			You regularly feel like you’re not in control of your life, as if you’re just along for the ride.`,
			functioning: `Sometimes you feel interested and present in your daily activities,
			but it comes and goes. There are days where your life feels outside of your control,
			and you’re just along for the ride. Your energy to take on the day isn’t consistent.`,
			flourishing: `You frequently feel interested and present in the daily activities of your life.
			Generally, you feel in control of your life, experiencing and making intentional
			choices about what you want. You regularly feel energized to take on the day.`
		},
		reflection: `
			If depression means worry over the past and anxiety means worry over the future,
			then in our book, presence means contentment.<br>
			Next time you reach a goal or realize a dream, try basking in the glow of the moment
			rather than moving on to the next accomplishment.
		`,
		chartOrder: 5,
		titleDescription: 'Being in the moment'
	},
	{
		key: 'purpose',
		title: 'Purpose',
		img: '/assets/icon/purpose.svg',
		imgActive: '/assets/icon/purpose_contrast.svg',
		description: `is knowledge of and intention to do things that are both personally meaningful
		and make a positive improvement to the lives of others.`,
		scoreSummary: {
			languishing: `You’re not generally satisfied with how your life is going.
			It feels like you’re wandering through life, waiting for something to guide you rather
			than being in charge. Your work frequently does not feel meaningful to you.`,
			functioning: `There are some days where your life doesn’t feel like it has a
			strong sense of purpose. There are days where you don’t feel as though your work
			brings meaning or joy, and while you sometimes contribute worthwhile things to
			your communities, it’s not as frequent as you’d like.`,
			flourishing: `You’re happy with how your life works: the things you do are meaningful and important,
			you feel a sense of direction and purpose when you make choices about your life,
			and you feel confident that you can make choices about your life with a sense of purpose.`
		},
		reflection: `
			Consider purpose to be the guiding light of your life, pointing you in the right direction and bringing
			you back when you’ve gone off course. <br>Purpose guides life decisions, influences behavior,
			shapes goals and creates meaning. <br>In Japan, there’s an idea known as ikigai, which is the concept
			of following your joy. It’s grown popular in the west as a way to help people find their dream careers.<br>
			Ikigai is finding the overlap between what you love and what the world needs.<br>
			You can discover and connect to your purpose by exploring what brings you joy and dedicating more time to it.
		`,
		chartOrder: 6,
		titleDescription: 'Clarity'
	}
];
