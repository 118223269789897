import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'monthYear'
})
export class MonthYearPipe implements PipeTransform {

  transform(value: string): string {
	const dateParts = value.split('-');
    return moment(`${dateParts[1]}-${dateParts[0]}-01`).format('MMMM YYYY');
  }

}
