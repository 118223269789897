/* eslint-disable curly */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { GOALS } from '../../user-information/helpers/constants';
import { doc, Firestore, setDoc, getDoc, updateDoc } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { AlertService } from '../../shared/helpers/alert.service';
import { environment } from 'src/environments/environment';
import { User } from '../types/user';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	item: Observable<any>;
	userInfoRoute: string;
	userPreferences: any = {
		interests: [],
		goals: []
	};

	private _user: any;
	private _currentStep = 2;
	private _userInfo: any = { goals: GOALS, availableTimes: [] };

	constructor(
		private firestore: Firestore,
		private alertService: AlertService
	) {
		const user = window.localStorage.getItem('plaey-user');
		if (user) {
			this.user = JSON.parse(user);
			console.log(this.user);
			if (!this._userInfo) this.getUserInfo();
		}
	}

	//#region props GETTERS & SETTERS
	get userInfo() { return this._userInfo; }
	get currentStep() { return this._currentStep; }
	get user() { return this._user; }
	set user(value: any) {
		this._user = value;
		window.localStorage.setItem('plaey-user', JSON.stringify(value));
		this.userInfoRoute = `users/${this.user?.uid}`;
	}
	//#endregion
	userExists = async () => Boolean((await getDoc(doc(this.firestore, this.userInfoRoute))).data()?.email);
	createUser = async (values: User) => {
		try {
			await setDoc(
				doc(this.firestore, this.userInfoRoute),
				values
			);
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	};


	updateUser = async (values: any) => {
		try {
			await updateDoc(
				doc(this.firestore, this.userInfoRoute),
				values
			);
			this._userInfo = { ...this.userInfo, ...values };
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	};

	getUserInfo = async () => {
		console.log('STARTING GET USER INFO');
		let data: any;
		try {
			if (!this.user) return data;
			const route = `users/${this.user?.uid}`;
			const docRef = doc(this.firestore, route);
			data = (await getDoc(docRef))?.data();
			data.uid = this.user?.uid;
			if (data) this._userInfo = data;
		} catch (err) {
			if (!environment.production) console.error(err);
			// await this.alertService.show('Something went wrong. Please try again.');
		}

		return data;
	};
}
