import { CATEGORIES } from '../../shared/helpers/categories';

export const EXPERIENCES = [
	{
		key: 'nature',
		title: 'Nature',
		description: 'Hiking, Grounding, Rock Climbing, etc...',
		selected: false
	},
	{
		key: 'sound',
		title: 'Sound',
		description: 'Concerts, Sound baths, Music lessons, etc...',
		selected: false
	},
	{
		key: 'taste',
		title: 'Taste',
		description: 'Cooking, Tastings, Horticulture, etc...',
		selected: false
	},
	{
		key: 'expression',
		title: 'Expression',
		description: 'Painting, Drawing, Acting, etc...',
		selected: false
	},
	{
		key: 'motion',
		title: 'Motion',
		description: 'Running, Yoga, Dancing, etc...',
		selected: false
	},
	{
		key: 'mind',
		title: 'Mind',
		description: 'Meditation, Mindfulness, etc...',
		selected: false
	},
	{
		key: 'soul',
		title: 'Soul',
		description: 'Energy work, cacao ceremonies, etc...',
		selected: false
	},
	{
		key: 'breath',
		title: 'Breath',
		description: 'Breathwork',
		selected: false
	}
];

export const STEPS = [
	{
		route: 'basic',
		title: `Let's get to know each other`,
	},
	{
		route: 'habits',
		title: 'Tell us about your habits'
	},
	{
		route: 'interests',
		title: 'What type of experiences are you most interested in?'
	}
];

export const RACES = [
	{ label: 'Asian', value: 'asian' },
	{ label: 'Hispanic/LatinX', value: 'hispanic' },
	{ label: 'Middle Eastern', value: 'eastern' },
	{ label: 'Black or African American', value: 'african' },
	{ label: 'Native Hawaiian or Pacific Islander', value: 'hawaiian' },
	{ label: 'White', value: 'white' },
	{ label: 'Other', value: 'other' },
	{ label: 'Prefer not to answer', value: 'unknown' }
];

export const GENDERS = [
	{ label: 'Male', value: 'M' },
	{ label: 'Female', value: 'F' },
	{ label: 'Transgender Female', value: 'TF' },
	{ label: 'Transgender Male', value: 'TM' },
	{ label: 'Non-binary', value: 'NB' },
	{ label: 'Other', value: 'O' },
	{ label: 'Prefer not to answer', value: 'U' }
];

export const SLEEP_HOURS = [
	{ label: '8 +', value: '8+' },
	{ label: '6 - 7', value: '6-7' },
	{ label: '4 - 5', value: '4-5' },
	{ label: 'Less than 4', value: '4-' }
];

export const DAY_HABITS = [
	{ label: 'Mostly indoors', value: 1 },
	{ label: 'Indoors but I go out on a regular basis', value: 2 },
	{ label: 'I spend the better part of the day on foot', value: 3 },
	{ label: 'Manual labor', value: 4 },
	{ label: 'Other', value: 0 }
];

export const GOALS = [
	{ key: CATEGORIES[5].key, title: 'Build a sense of purpose', img: CATEGORIES[5].imgActive, order: 1 },
	{ key: CATEGORIES[3].key, title: 'Boost my confidence', img: CATEGORIES[3].imgActive, order: 2 },
	{ key: CATEGORIES[1].key, title: 'Improve my adaptability', img: CATEGORIES[1].imgActive, order: 3 },
	{ key: CATEGORIES[2].key, title: 'Improve my joy', img: CATEGORIES[2].imgActive, order: 4 },
	{ key: CATEGORIES[4].key, title: 'Be more present', img: CATEGORIES[4].imgActive, order: 5 },
	{ key: CATEGORIES[0].key, title: 'Feel more connected', img: CATEGORIES[0].imgActive, order: 6 }
];
export enum WEEKDAY {
	monday,
	tuesday,
	wednesday,
	thursday,
	friday,
	saturday,
	sunday

}
export enum AvailableBlock {
	evening = 'Evening',
	morning = 'Morning',
	midday = 'Mid-Day',
}

export const availabilityBlocks = [
	{
		label: 'M',
		value: WEEKDAY.monday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'T',
		value: WEEKDAY.tuesday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'W',
		value: WEEKDAY.wednesday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'Th',
		value: WEEKDAY.thursday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'F',
		value: WEEKDAY.friday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'Sa',
		value: WEEKDAY.saturday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	},
	{
		label: 'Su',
		value: WEEKDAY.sunday,
		blocks: [AvailableBlock.morning, AvailableBlock.midday, AvailableBlock.evening],
	}
];

export type AvailabilityBlocksUser = {
	day: WEEKDAY;
	blocks: AvailableBlock[];
};
export const TIMES = [
	{ key: 1, start: '6 AM', end: '11 AM' },
	{ key: 2, start: '11 AM', end: '1 PM' },
	{ key: 3, start: '1 PM', end: '6 PM' },
	{ key: 4, start: '6 PM', end: '10 PM' },
];

export const datesBlockValidator = {
	morning: (hour) => hour >= 6 && hour < 12,
	evening: (hour) => hour >= 18 && hour <= 22,
	'mid-day': (hour) => hour >= 12 && hour < 18,
};
