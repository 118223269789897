/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: "AIzaSyBQY1-KAnvyzM2jego8bnkBmiJcn4dKCww",
		authDomain: "plaey-app-new.firebaseapp.com",
		projectId: "plaey-app-new",
		storageBucket: "plaey-app-new.appspot.com",
		messagingSenderId: "555840374666",
		appId: "1:555840374666:web:dfc7b9bd0fbe1145b02a73",
		measurementId: "G-MJKN362NZ0"
	},
	GTMId: 'GTM-5GL4W5W',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
