/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { AssessmentService } from 'src/app/core/services/assessment.service';

@Injectable({
	providedIn: 'root'
})
export class UserInformationGuard implements CanLoad, CanActivate {
	constructor(
		private router: Router,
		private userState: UserService,
		private assessmentState: AssessmentService
	) { }

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.userState.getUserInfo().then(async v => {
			if (!v?.birthdate) return this.router.parseUrl('/user-information');
			else {
				const assessmentExists = await this.assessmentState.getLastAssessment();
				if (!assessmentExists) return this.router.parseUrl(`/user-information/assessment`);
				if (!v?.availableTimes.reduce((prev, day) => prev + day.blocks.length, 0))
					return this.router.parseUrl('/user-information/preferences');
				return true;
			}
		});
	}

	canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.userState.getUserInfo().then(async v => {
			if (!v?.birthdate) return this.router.parseUrl('/user-information');
			else {
				const assessmentExists = await this.assessmentState.getLastAssessment();
				if (!assessmentExists) return this.router.parseUrl(`/user-information/assessment`);
				if (!v?.availableTimes.reduce((prev, day) => prev + day.blocks.length, 0))
					return this.router.parseUrl('/user-information/preferences');
				return true;
			}
		});
	}
}
