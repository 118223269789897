import { Injectable } from '@angular/core';
import { Session, WeekDay } from '../types/sessions';
import {
	doc,
	docData,
	getDoc,
	getDocs,
	query,
	where,
	updateDoc,

	setDoc,
	deleteDoc,
	Firestore,
	collection,


} from '@angular/fire/firestore';
import * as moment from 'moment';
import { map, take, skip } from 'rxjs/operators';
import { addDoc } from '@angular/fire/firestore';

import { getDownloadURL, getStorage, ref, uploadBytes } from '@angular/fire/storage';
import Papa from 'papaparse';
import { Service } from '../types/services';
import { Experience } from '../types/experience';
import { BehaviorSubject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class SessionsService implements Service<Session> {

	constructor(
		private firestore: Firestore,
	) { }

	async create(session: Session, experienceUid: string,) {
		const document = doc(this.firestore, 'experiences', experienceUid, 'sessions', session.weekDay);
		await setDoc(document, { ...session, uid: session.weekDay });
		return { ...session, uid: session.weekDay };

	}

	update = async (experienceUid: string, session: any) => {

		const sessionRef = doc(this.firestore, `experiences/${experienceUid}/sessions/${session.weekDay}`);
		await updateDoc(sessionRef, session);
		return session;
	};

	async delete(sessionUid: string, experienceUid: string) {

		const experienceRef = doc(this.firestore, `experiences/${experienceUid}sessions/${sessionUid}`);
		await deleteDoc(experienceRef);
	}

	async getOne(sessionUid: string, experienceUid: string) {
		const sessionRef = doc(this.firestore, `experiences/${experienceUid}/sessions/${sessionUid}`);
		return { ...(await getDoc(sessionRef)).data() as Session, uid: sessionUid };
	}
	async getAllByExperience(experienceUid: string) {
		const sessionRef = collection(this.firestore, `experiences/${experienceUid}/sessions`);
		return (await getDocs(sessionRef))
			.docs.reduce((result, x) => ({
				...result, [x.id]: {
					...(x.data() as Session)
					, uid: x.id
				}
			}), {} as Record<string, Session>);

	}
	async getAllSessionForThisMonth(experienceId: string) {
		try {
			const sessions = await this.getAllByExperience(experienceId);
			let response: Array<{ startAt: number; uid: string; weekDay: WeekDay }> = [];
			for (const session of Object.values(sessions)) {
				response = response.concat(await this.getOneSessionForThisMonth(session.uid, experienceId));
			}

			return response;
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async getOneSessionForThisMonth(sessionUid: string, experienceId: string) {
		const nowTimestamp = moment().unix();
		const endMonthTimestamp = moment().endOf('month').unix();
		const session = await this.getOne(sessionUid, experienceId);
		let response: Array<{ startAt: number; uid: string; weekDay: WeekDay }> = [];
		if (session.weekDay === 'Specific') {
			const times = session.times.map(x => moment(x));
			const filteredTimes = times.filter(x => x.unix() >= nowTimestamp && x.unix() <= endMonthTimestamp);
			response = response.concat(filteredTimes.map(x =>
			({
				startAt: x.milliseconds(0).seconds(0).unix(),
				uid: `${session.uid}-${x.milliseconds(0).seconds(0).unix()}`,
				weekDay: session.weekDay
			})));
		} else {
			const now = moment();
			const endMonth = moment().endOf('month');
			const times = session.times;
			while (now.isBefore(endMonth)) {
				if (now.format('dddd') === session.weekDay) {

					response = response.concat(times.map(x =>
					({
						startAt: now.clone().hour(Number(x.split(':')[0])).seconds(0).milliseconds(0)
							.minute(Number(x.split(':')[1])).unix(),
						uid: `${session.uid}-${now.clone().hour(Number(x.split(':')[0])).seconds(0).milliseconds(0)
							.minute(Number(x.split(':')[1])).unix()}`,
						weekDay: session.weekDay
					})));
				}
				now.add(1, 'day');
			}
		}


		return response;
	}

	async getOneSessionForThisMonthByUid(sessionDateUid: string, experienceId: string) {

		const [sessionUid, timestamp] = sessionDateUid.split('-');
		console.log(sessionUid, timestamp);
		const sessions = await this.getOneSessionForThisMonth(sessionUid, experienceId);
		console.log(sessions);
		return sessions.find(x => x.startAt === Number(timestamp));

	}


	bulk(file: File) {
		const formatSession = (session: { 'Exp ID': number; Date: string; Time: string }) => {
			if (Object.keys(WeekDay).includes(session.Date.toLowerCase())) {
				return {
					weekDay: session.Date,
					periodic: 'weekly',
					time: session.Time,
					experienceUid: session['Exp ID'],
					uid: session.Date

				};
			} else {
				return {
					weekDay: 'Specific',
					periodic: 'once',
					time: `${session.Date} ${session.Time}`,
					experienceUid: session['Exp ID'],
					uid: session.Date

				};
			}

		};

		const loadingSubject = new BehaviorSubject([]);
		Papa.parse(file, {
			header: true,
			complete: async ({ data }) => {
				const rawSessions = data.map(formatSession);
				const rawSessionsReduced: Record<string, Record<WeekDay, {
					times: Array<string>;
					periodic: string;
					experienceUid: string;
					weekDay: string;
				}>> = rawSessions.reduce((result, x) =>
				({
					...result, [x.experienceUid]:
					{
						...(result[x.experienceUid] || Object.keys(WeekDay)
							.reduce((rsult, d) =>
							({
								...rsult,
								[WeekDay[d].value]: {
									times: [],
									periodic: d === 'specific' ? 'once' : 'weekly',
									experienceUid: x.experienceUid,
									weekDay: d,
									uid: x.uid
								}
							}), {})),
						[x.weekDay]: {
							...(result[x.experienceUid]?.[x.weekDay] || {

							}),
							times: [...(result[x.experienceUid]?.[x.weekDay]?.times || []), x.time],
							periodic: x.periodic,
							experienceId: x.experienceUid,
							weekDay: x.weekDay,
							uid: x.uid
						}
					}
				}),
					{} as Record<string, Record<WeekDay, { times: Array<string> }>>);

				const sessions = await Promise.all(Object.entries(rawSessionsReduced).map(async ([experienceUid, session]) => (
					await Promise.all(Object.keys(session).map(async (weekDay) => await this.create(session[weekDay], experienceUid)))
				)));
				loadingSubject.next(sessions);
				return;


			}
		});

		return loadingSubject.asObservable().pipe(
			skip(1),
			take(2),
		);;



	}

}


/// get All the days that are in a weekDay example 0 is monday and 6 is sunday
