import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { UserInformationGuard } from './core/guards/user-information.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthPageModule)
	},
	{
		path: 'user-information',
		loadChildren: () => import('./user-information/user-information.module').then(m => m.UserInformationPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),

	},
	{
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
		canLoad: [AuthGuard, UserInformationGuard]
	},




];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
