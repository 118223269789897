/* eslint-disable curly */
/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { addDoc, collection, doc, Firestore, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { AlertService } from 'src/app/shared/helpers/alert.service';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CATEGORIES } from 'src/app/shared/helpers/categories';
import { ASSESSMENT_QUESTIONS } from 'src/app/shared/helpers/assessmentQuestions';
import { BehaviorSubject } from 'rxjs';
import { Experience } from '../types/experience';
import { SessionsService } from './sessions.service';

@Injectable({
	providedIn: 'root'
})
export class AssessmentService {
	currentAssessmentId: any;
	assessment: any;
	rules = {};
	private _assessmentResponses = {};
	private assessmentsSubject = new BehaviorSubject([]);

	constructor(
		private firestore: Firestore,
		private alertService: AlertService,
		private userState: UserService,
		private sessionService: SessionsService
	) {
		this.loadAssessments();
	}

	get assessmentResponses() { return this._assessmentResponses; }
	get assessments() { return this.assessmentsSubject.asObservable(); };

	getLastAssessment = async () => {
		let docRef: any;
		let data: any;

		try {
			const route = `assessments/${this.userState.user.uid}/globals`;
			const collectionRef = collection(this.firestore, route);
			const docSnap = await getDocs(collectionRef);

			docSnap.forEach(document => {
				docRef = document;
			});
			// THE LAST ONE - .length does not exist
			if (docRef?.id) {
				this.currentAssessmentId = docRef.id;
				const { responses, ...attrs } = docRef.data();
				this._assessmentResponses = responses;
				data = responses;
				this.assessment = attrs;
			}
		} catch (err) {
			if (!environment.production) console.error(err);
		}
		return data;
	};

	getAssessmentByMonthId = async (monthId: string) => {
		let data: any;
		const route = `assessments/${this.userState.user.uid}/globals/${monthId}`;
		const docRef = doc(this.firestore, route);

		try {
			const assessment = await getDoc(docRef);

			if (assessment?.id) {
				this.currentAssessmentId = assessment.id;
				const { responses, ...attrs } = assessment.data();
				this._assessmentResponses = responses;
				data = responses;
				this.assessment = attrs;
			}
		} catch (err) {
			if (!environment.production) console.error(err);
		}
		return data;
	};

	saveInitialAssessment = async (value, monthYear?: string) => {
		this._assessmentResponses = value;
		const now = moment();
		const currentMonthId = monthYear || now.format('MM-YYYY');
		try {
			const route = `assessments/${this.userState.user.uid}/globals/${currentMonthId}`;
			const body = {
				createdAt: now.unix(),
				updatedAt: now.unix(),
				date: currentMonthId,
				responses: this.assessmentResponses,
				...this.getScores()
			};
			await setDoc(doc(this.firestore, route), body);
			this.assessment = body;
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	};

	getScores = () => {
		if (!Object.keys(this.assessmentResponses).length) return;
		let categoriesSum = 0;
		const responses = this.assessmentResponses;

		const domainResults = {};

		for (const domain of CATEGORIES) {
			const questions = ASSESSMENT_QUESTIONS.filter(x => x.category === domain.key);
			const responseValues = questions.map(x => {
				let value = responses[x.key];
				if (x.reverse) {
					if (value === 0) value = 5;
					else if (value === 1) value = 4;
					else if (value === 2) value = 3;
					else if (value === 3) value = 2;
					else if (value === 4) value = 1;
					else value = 1;
				}
				return value * 2;
			});
			const avgValue = responseValues.reduce((old, val) => old + val) / questions.length;
			domainResults[domain.key] = avgValue;
			categoriesSum = categoriesSum + avgValue;
		}

		const categoriesAvg = categoriesSum / CATEGORIES.length;
		return {
			result: Number(categoriesAvg.toFixed(2)),
			domainResults
		};
	};

	loadAssessments = async () => {
		const route = `assessments/${this.userState.user.uid}/globals`;
		const assessmnetsCollection = collection(this.firestore, route);
		//
		onSnapshot(assessmnetsCollection, (assessments) => {
			const assessmentsData = [];
			//
			assessments.docs.forEach(async (x) => {
				const { domainResults, result } = x.data();
				assessmentsData.push({ id: x.id, domainResults, result });
			});
			//
			this.assessmentsSubject.next(assessmentsData);
		});
	};

	getExperiencesAssessments = async (experiences: Experience[], currentMonth) => {

		const experienceAssessmentsRoute = `assessments/${this.userState.user.uid}/experiences`;
		const assessmentsCollection = collection(this.firestore, experienceAssessmentsRoute);
		this.rules = {};
		//
		const bookingsReq = await getDocs(query(
			collection(this.firestore, 'bookings'),
			where('userId', '==', this.userState.user.uid),
			where('suggestionId', '==', currentMonth),
			where('experienceId', 'in', experiences.map(x => x.uid)),
		));
		const bookings = [];
		bookingsReq.docs.forEach(x => bookings.push(x.data()));
		//
		console.log(experiences);
		for (const experience of experiences) {
			const booking = bookings.find(x => x.experienceId === experience.uid);
			let assessmentsRules = {
				disablePre: true,
				disablePost: true,
				disableBook: false,
				disableReview: false,
				sessionId: booking?.sessionId || '',
				sessionDate: moment().unix()
			};
			if (booking) {
				const session = await (await this.sessionService.getOneSessionForThisMonthByUid(booking.sessionId, booking.experienceId));
				console.log(session);

				if (!session) continue;
				assessmentsRules = { ...assessmentsRules, disablePre: false, disablePost: true, disableBook: true };
				//
				const assessments = await getDocs(query(
					assessmentsCollection,
					where('experienceId', '==', experience.uid),
					where('sessionId', '==', booking.sessionId)
				));
				const assessment = assessments.docs[0]?.data();
				if (assessment) {
					assessmentsRules.disablePre = Boolean(assessment.pre);
					assessmentsRules.disablePost = !Boolean(assessment.pre) || Boolean(assessment.post);
				}
				if (!assessmentsRules.disablePost && moment().diff(moment.unix(session.startAt), 'minutes') < 0) {
					assessmentsRules.disablePost = true;
					assessmentsRules.disableReview = true;
				}
				assessmentsRules.sessionDate = session.startAt;
			}
			this.rules[experience.uid] = assessmentsRules;
		}
	};

	async saveAssessment(isPreAssessment: boolean, { experienceId, sessionId, ...data }: any) {
		const now = moment();
		const route = `assessments/${this.userState.user.uid}/experiences`;
		const experienceAssessmentsCollection = collection(this.firestore, route);
		//
		try {
			const body = { ...data, createdAt: now.unix() };
			if (isPreAssessment) await addDoc(
				experienceAssessmentsCollection,
				{
					pre: body,
					experienceId,
					sessionId
				}
			);
			else {
				const assessments = await getDocs(query(experienceAssessmentsCollection, where('experienceId', '==', experienceId)));
				// GET LAST ASSESSMENT
				let experienceAssessmentId = '';
				assessments.forEach((x) => { experienceAssessmentId = x.id; });
				// MUTATE DOC
				if (!experienceAssessmentId) await addDoc(
					experienceAssessmentsCollection,
					{
						post: body,
						experienceId,
						sessionId
					}
				);
				else await updateDoc(doc(this.firestore, `${route}/${experienceAssessmentId}`), { post: body });
			}
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	}

	async saveReview(data: any, experience) {
		const now = moment();
		const route = `experiences/${experience.id}/reviews`;
		const assessmentRef = collection(this.firestore, route);
		//
		try {
			await addDoc(
				assessmentRef,
				{
					...data,
					userId: this.userState.user.uid,
					experienceId: experience.id,
					sessionId: experience.sessionId,
					createdAt: now.unix()
				}
			);
		} catch (err) {
			console.error(err);
			await this.alertService.show('Something went wrong. Please try again.');
		}
	}

	async checkReviewByExperienceId(experienceId: string, invitationId: string) {
		const route = `experiences/${experienceId}/reviews`;
		const reviewsCollection = collection(this.firestore, route);
		const reviews = await getDocs(
			query(reviewsCollection, where('userId', '==', this.userState.user.uid), where('invitationId', '==', invitationId))
		);
		// GET LAST ASSESSMENT
		let reviewId = '';
		reviews.forEach((x) => { reviewId = x.id; });
		// EXISTS
		return Boolean(reviewId);
	}
}
