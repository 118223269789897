import { CATEGORIES } from './categories';

export const ASSESSMENT_QUESTIONS = [
	{
		label: 'that you had warm and trusting relationships with others?',
		key: 'trustingRelationships',
		category: CATEGORIES[0].key
	},
	{
		label: 'that you were helping make your community a better place?',
		key: 'helpCommunity',
		category: CATEGORIES[0].key
	},
	{
		label: 'that you belonged to a community?',
		key: 'belongsToCommunity',
		category: CATEGORIES[0].key
	},
	{
		label: 'disconnected from other people in your life?',
		key: 'disconnectedFromPeople',
		reverse: true,
		category: CATEGORIES[0].key
	},
	{
		label: 'comfortable thinking or expressing your own ideas and opinions?',
		key: 'comfortableExpressingYourself',
		category: CATEGORIES[1].key
	},
	{
		label: 'good at managing the responsibilities of your daily life?',
		key: 'responsabilitiesManagement',
		category: CATEGORIES[1].key
	},
	{
		label: 'exposed to new experiences that challenged how you think about yourself or the world?',
		key: 'exposedNewExperiences',
		category: CATEGORIES[1].key
	},
	{
		label: 'that you had experiences that challenged you to grow and become a better person?',
		key: 'growExperiences',
		category: CATEGORIES[1].key
	},
	{
		label: 'happy?',
		key: 'happy',
		category: CATEGORIES[2].key
	},
	{
		label: 'calm and peaceful?',
		key: 'calm',
		category: CATEGORIES[2].key
	},
	{
		label: 'excited about your day?',
		key: 'excited',
		category: CATEGORIES[2].key
	},
	{
		label: 'that the demands of your daily life get you down?',
		reverse: true,
		key: 'depressed',
		category: CATEGORIES[2].key
	},
	{
		label: 'happy with how things are turning out in your life?',
		key: 'happyThings',
		category: CATEGORIES[3].key
	},
	{
		label: 'confident in your opinions, even when they were different from others?',
		key: 'confident',
		category: CATEGORIES[3].key
	},
	{
		label: 'that you liked most parts of yourself?',
		key: 'likeYourself',
		category: CATEGORIES[3].key
	},
	{
		label: 'that you were accepting of others, even those different from you?',
		key: 'acceptDifferentPeople',
		category: CATEGORIES[3].key
	},
	{
		label: 'in charge of your life situation?',
		key: 'inChargeOwnLife',
		category: CATEGORIES[4].key
	},
	{
		label: 'interested in the things happening daily in your life?',
		key: 'dailyThingsInterest',
		category: CATEGORIES[4].key
	},
	{
		label: 'that the way society works made sense?',
		key: 'likeSocietyWay',
		category: CATEGORIES[4].key
	},
	{
		label: 'energized?',
		key: 'energized',
		category: CATEGORIES[4].key
	},
	{
		label: 'satisfied?',
		key: 'satisfied',
		category: CATEGORIES[5].key
	},
	{
		label: 'aimlessly wandering through life?',
		reverse: true,
		key: 'aimlesslyThroughLife',
		category: CATEGORIES[5].key
	},
	{
		label: 'that your life has a sense of direction or meaning to it?',
		key: 'lifeMeaningSense',
		category: CATEGORIES[5].key
	},
	{
		label: 'that you had something important to contribute to your community?',
		key: 'communityContribution',
		category: CATEGORIES[5].key
	},
	{
		label: 'like your daily activities DO NOT contribute anything worthwhile to others?',
		reverse: true,
		key: 'likeDailyActivities',
		category: CATEGORIES[5].key
	},
	{
		label: 'tense, restless, or nervous because of what’s going on in your life?',
		key: 'nervous',
		category: 'N/A'
	}
];

export const PRE_ASSESSMENT_QUESTIONS = [
	{
		label: 'I feel confident',
		key: 'confidence',
		category: CATEGORIES[3].key
	},
	{
		label: 'I feel connected to others around me',
		key: 'connection',
		category: CATEGORIES[0].key
	},
	{
		label: `I feel present in the things I'm doing`,
		key: 'presence',
		category: CATEGORIES[4].key
	},
	{
		label: 'I feel open to growth and change',
		key: 'adaptability',
		category: CATEGORIES[1].key
	},
	{
		label: 'I feel happy',
		key: 'joy',
		category: CATEGORIES[2].key
	},
	{
		label: `I'm doing things that are important and meaningful to me`,
		key: 'purpose',
		category: CATEGORIES[4].key
	},

];

export const POST_ASSESSMENT_QUESTIONS = [
	{
		label: 'I feel confident',
		key: 'confidence',
		category: CATEGORIES[3].key
	},
	{
		label: 'I feel connected to others around me',
		key: 'connection',
		category: CATEGORIES[0].key
	},
	{
		label: `I feel present and focused`,
		key: 'presence',
		category: CATEGORIES[4].key
	},
	{
		label: 'I feel challenged to do something new',
		key: 'adaptability',
		category: CATEGORIES[1].key
	},
	{
		label: 'I feel happy',
		key: 'joy',
		category: CATEGORIES[2].key
	},
	{
		label: `I feel I did something important for myself`,
		key: 'purpose',
		category: CATEGORIES[4].key
	},
	{
		label: 'I feel tense, restless or nervous',
		key: 'tense',
		category: 'N/A'
	},
	{
		label: 'I feel energized',
		key: 'energized',
		category: 'N/A'
	}
];
